"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.formDialog,
      width: _vm.dialogWidth,
      "custom-class": "input-dialog",
      title: _vm.title
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.formDialog = $event;
      },
      close: _vm.closeDialog
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "status-icon": "",
      "label-width": _vm.labelWidth,
      rules: _vm.rules
    }
  }, _vm._l(_vm.labelList, function (item, index) {
    return _c("el-form-item", {
      key: "formItem-" + index,
      attrs: {
        label: item.label + "：",
        prop: item.prop,
        required: item.required,
        rules: [{
          required: item.required,
          message: _vm.inputRuleArr.includes(item.type) ? "\u8BF7\u8F93\u5165".concat(item.label) : "\u8BF7\u9009\u62E9".concat(item.label)
        }]
      }
    }, [item.type === "input" ? _c("el-input", {
      attrs: {
        disabled: item.disabled,
        placeholder: "请输入"
      },
      model: {
        value: _vm.form[item.prop],
        callback: function callback($$v) {
          _vm.$set(_vm.form, item.prop, $$v);
        },
        expression: "form[item.prop]"
      }
    }) : item.type === "select" ? _c("el-select", {
      attrs: {
        disabled: item.disabled,
        filterable: ""
      },
      on: {
        change: function change($event) {
          return _vm.change($event, item);
        }
      },
      model: {
        value: _vm.form[item.prop],
        callback: function callback($$v) {
          _vm.$set(_vm.form, item.prop, $$v);
        },
        expression: "form[item.prop]"
      }
    }, _vm._l(item.select, function (element, i) {
      return _c("el-option", {
        key: i,
        attrs: {
          label: element.label,
          value: element.value
        }
      });
    }), 1) : item.type === "multipleSelect" ? _c("el-select", {
      attrs: {
        disabled: item.disabled,
        multiple: ""
      },
      on: {
        change: function change($event) {
          return _vm.multipleChange($event, item);
        }
      },
      model: {
        value: _vm.form[item.prop],
        callback: function callback($$v) {
          _vm.$set(_vm.form, item.prop, $$v);
        },
        expression: "form[item.prop]"
      }
    }, _vm._l(item.select, function (element, i) {
      return _c("el-option", {
        key: i,
        attrs: {
          label: element.label,
          value: element.value
        }
      });
    }), 1) : item.type === "date" ? _c("el-date-picker", {
      attrs: {
        type: "date",
        placeholder: "选择日期",
        "value-format": "yyyy-MM-dd"
      },
      on: {
        change: _vm.changeDate
      },
      model: {
        value: _vm.form[item.prop],
        callback: function callback($$v) {
          _vm.$set(_vm.form, item.prop, $$v);
        },
        expression: "form[item.prop]"
      }
    }) : item.type === "image" ? _c("div", {
      staticClass: "image-upload-wrap"
    }, [_vm.form[item.prop] ? _c("el-image", {
      staticClass: "avatar",
      attrs: {
        src: _vm.form[item.prop],
        "preview-src-list": [_vm.form[item.prop]]
      }
    }) : _c("el-upload", {
      staticClass: "avatar-uploader",
      attrs: {
        headers: _vm.headers,
        action: _vm.action,
        "show-file-list": false,
        "on-success": function onSuccess(response, file, fileList) {
          return _vm.handleImgSuccess(response, file, fileList, index);
        },
        "before-upload": _vm.beforeImgUpload
      }
    }, [_vm.form[item.prop] ? _c("img", {
      staticClass: "avatar",
      attrs: {
        src: _vm.form[item.prop]
      }
    }) : _c("i", {
      staticClass: "el-icon-plus avatar-uploader-icon"
    })]), _vm.form[item.prop] ? _c("i", {
      staticClass: "el-icon-remove",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return function () {
            return _vm.imageRemove(index);
          }.apply(null, arguments);
        }
      }
    }) : _vm._e()], 1) : _vm._e()], 1);
  }), 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")]), _vm.saveAndNext ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitAndAddNext
    }
  }, [_vm._v("保存并新增下一条")]) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;