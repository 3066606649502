"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _default = exports.default = {
  name: 'Filter-box',
  props: {
    clearSearchVal: {
      // 是否点击了清除按钮
      type: Boolean
    },
    syncDataName: {
      // 搜索框前面是否有空格
      type: String
    },
    isGroupBtn: {
      // 厂商管理，设备列表按钮组
      type: Boolean
    },
    rightBtnName: {
      // 右侧按钮
      type: String
    },
    addBtnName: {
      // 添加按钮名
      type: String
    },
    searchPlaceholder: {
      type: String
    },
    moreShowList: {
      // 更多展示列表
      type: Array
    },
    moreSelectBtn: {
      // 是否显示更多筛选
      type: Boolean,
      default: true
    },
    isShowSelect: {
      // 是否显示展示筛选
      type: Boolean,
      default: true
    },
    isClearBtn: {
      // 是否显示清空筛选
      type: Boolean,
      default: true
    },
    checkListValue: {
      type: Array
    }
  },
  data: function data() {
    return {
      btnList: [{
        name: '设备列表'
      }, {
        name: '员工管理'
      }, {
        name: '传感器管理'
      }, {
        name: '品牌管理'
      }, {
        name: '基础信息'
      }],
      breadList: [],
      searchVal: '',
      moreShow: false,
      moreSelectVisible: false,
      checkList: [],
      serchUserType: '',
      serchUserState: ''
    };
  },
  // created () {
  //   this.getBreadcrumb()
  // },
  watch: {
    // $route () {
    //   this.getBreadcrumb()
    // },
    clearSearchVal: function clearSearchVal(val) {
      if (val === true) {
        this.searchVal = '';
        val = false;
        this.$emit('resetSearchVal', val);
      }
    },
    checkListValue: function checkListValue() {
      this.checkList = this.checkListValue;
    },
    checkList: function checkList(newValue, oldValue) {
      if (newValue === true || newValue === false) {
        this.checkList = oldValue;
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var breadNumber = typeof this.$route.meta.breadNumber !== 'undefined' ? this.$route.meta.breadNumber : 0;
      var query = this.$route.query;
      var newBread;
      if (Object.keys(query).length && query.breadTitle) {
        newBread = {
          name: query.breadTitle,
          path: this.$route.fullPath
        };
      } else {
        newBread = {
          name: this.$route.meta.title,
          path: this.$route.fullPath
        };
      }
      this.breadList = JSON.parse(sessionStorage.getItem('breadListStorage')) || [];
      this.breadList.splice(breadNumber, this.breadList.length - breadNumber, newBread);
      sessionStorage.setItem('breadListStorage', JSON.stringify(this.breadList));
    },
    /* 同步数据 */syncData: function syncData() {
      this.$emit('syncData');
    },
    /* 清空筛选条件 */clearSelect: function clearSelect() {
      this.searchVal = '';
      this.$emit('clearSelect');
    },
    clickHandel: function clickHandel(name) {
      this.$store.commit('changeBtnGroupTitle', name);
      var obj = new Map([['设备列表', 'deviceList'], ['员工管理', 'interImplement'], ['传感器管理', 'interSensor'], ['品牌管理', 'interBrand'], ['基础信息', 'editManuf']]);
      var action = obj.get(name);
      this.$emit(action);
    },
    /* 点击搜索按钮 */interSearch: function interSearch() {
      this.$emit('interSearch', this.searchVal);
    },
    /* 添加用户之类的弹窗 */changeAddDialog: function changeAddDialog() {
      this.$emit('changeAddDialog');
    },
    /* 右侧按钮事件 */handelRightBtn: function handelRightBtn() {
      this.$emit('handelRightBtn');
    },
    /* 点击 更多筛选按钮 */moreSelectFn: function moreSelectFn() {
      if (this.moreShow === true) {
        this.moreShow = false;
      }
      this.moreSelectVisible = !this.moreSelectVisible;
      this.$emit('moreSelectVisible', this.moreSelectVisible);
    },
    /* 点击 更多展示按钮 */moreShowFn: function moreShowFn() {
      this.moreShow = !this.moreShow;
      if (this.moreSelectVisible === true) {
        this.moreSelectVisible = false;
        this.$emit('moreSelectVisible', this.moreSelectVisible);
      }
      this.checkList = [];
    },
    /* 更多展示checkbox选项 */checkboxChange: function checkboxChange(val) {
      var checkboxList = this.moreShowList.map(function (ele) {
        var i = val.indexOf(ele.title);
        if (i < 0) {
          ele.ischeck = false;
        } else {
          ele.ischeck = true;
        }
        return ele;
      });
      this.$emit('changeCheckbox', checkboxList);
    }
  }
};