"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: "index",
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: String,
      default: '500px'
    },
    labelWidth: {
      type: String,
      default: '150px'
    },
    saveAndNext: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      formDialog: false,
      // width: this.dialogWidth,
      form: {},
      labelList: [],
      rules: {},
      buttonGroup: [],
      headers: {
        Authorization: localStorage.token
      },
      action: process.env.VUE_APP_BASE_API + 'xhc/upload?action=uploadimage',
      inputRuleArr: ['input'],
      selectRuleArr: ['select', 'image', 'multipleSelect', 'date']
    };
  },
  methods: {
    /**
     * 添加
     **/
    showDialog: function showDialog(labelList) {
      var _this = this;
      this.labelList = labelList;
      this.formDialog = true;
      this.$nextTick(function () {
        _this.$refs.form.clearValidate();
      });
    },
    /**
     * 表单详情
     **/
    showForm: function showForm(form, labelList) {
      this.form = form;
      this.labelList = labelList;
      this.formDialog = true;
    },
    /**
     * 关闭表单弹窗
     **/
    closeDialog: function closeDialog() {
      // 重置表单
      this.$refs.form.resetFields();
      this.form = {};
      this.labelList = {};
      this.formDialog = false;
    },
    /**
     * 重置表单
     **/
    resetForm: function resetForm() {
      this.$refs.form.resetFields();
    },
    /**
     * 按钮点击
     **/
    cancel: function cancel() {
      this.formDialog = false;
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this2.$emit('submit', _this2.form);
        } else {
          return false;
        }
      });
    },
    submitAndAddNext: function submitAndAddNext() {
      var _this3 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this3.$emit('submitAndAddNext', _this3.form);
        } else {
          return false;
        }
      });
    },
    /**
     * 下拉框选择
     **/
    change: function change(val, item) {
      this.$forceUpdate();
      this.$emit('selectChange', val, item);
    },
    /**
     * 多选下拉框选择
     **/
    multipleChange: function multipleChange(val, item) {
      this.$forceUpdate();
      this.$emit('multipleChange', val, item);
    },
    /**
     * 图片上传
     **/
    handleImgSuccess: function handleImgSuccess(res, file, fileList, index) {
      this.$set(this.form, this.labelList[index]['prop'], '');
      this.form[this.labelList[index]['prop']] = res.data;
      this.$emit('handleImgSuccess', res, file);
    },
    /**
     * 移除图片
     **/
    imageRemove: function imageRemove(index) {
      this.$set(this.form, this.labelList[index]['prop'], '');
      this.form[this.labelList[index]['prop']] = '';
    },
    /**
     * 图片上传之前
     **/
    beforeImgUpload: function beforeImgUpload(file) {
      var isJPG = ['image/jpg', 'image/jpeg', 'image/png'].indexOf(file.type);
      var isLt2M = file.size / 1024 / 1024 < 3;
      if (isJPG < 0) {
        this.$message.error('图片只能是 JPG、PNG 格式');
        return false;
      }
      // if (!isLt2M) {
      //   this.$message.error('图片大小不能超过 3MB!');
      //   return false;
      // }
      return isJPG && isLt2M;
    },
    /**
     * 清空下拉列表
     **/
    clearSelect: function clearSelect(model) {
      this.form[model] = undefined;
    },
    changeDate: function changeDate(val) {
      this.$emit('changeDate', val);
    }
  }
};