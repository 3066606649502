"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  props: {
    defaultVal: String
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var s = time.getTime();
          var month = _this.Moment(s).format('M');
          return Number(month) > 4;
        }
      },
      value: '',
      showVal: ''
    };
  },
  mounted: function mounted() {
    if (this.defaultVal) {
      this.value = this.defaultVal;
    }
  },
  watch: {
    value: function value(val) {
      this.$emit('change', val);
      if (!val) {
        this.showVal = '';
        this;
        return;
      }
      var y = this.Moment(val).format('YYYY');
      var m = this.Moment(val).format('M');
      var obj = {
        '1': '一季度',
        '2': '二季度',
        '3': '三季度',
        '4': '四季度'
      };
      this.showVal = "".concat(y, " ").concat(obj[m]);
    }
  }
};